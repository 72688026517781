import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import PT from 'prop-types'
// import RRPT from 'react-router-prop-types'
import Helmet from 'react-helmet'
import HeaderMask from './HeaderMask'
import {closeWindow} from '../../utils/pwHybrid'
import imgBack from '../../resource/images/nav_icon_back@3x.png'
import imgBackBlack from '../../resource/images/nav_icon_back_black@3x.png'
import './index.scss'

class HeaderNav extends React.Component {
  constructor(props) {
    super(props)
    this.refRouter = null
    this.state = {}
  }

  render() {
    const {pt, backgroundColor, title, titleStyle} = this.props
    return (
      <div className="HeaderNav">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <HeaderMask paddingTop={pt} backgroundColor={backgroundColor} />
        <div
          className="HeaderNav__header"
          style={{
            paddingTop: pt,
          }}
        >
          <div className="HeaderNav__headerMain">
            <div
              className="HeaderNav__close"
              onClick={() => {
                closeWindow()
              }}
              role="button"
              tabIndex="0"
            >
              <img
                src={titleStyle.color === '#000' ? imgBackBlack : imgBack}
                alt=""
              />
            </div>
            <div className="HeaderNav__title" style={titleStyle}>
              {title}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HeaderNav.propTypes = {
  title: PT.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PT.object,
  backgroundColor: PT.string,
  pt: PT.number.isRequired,
}

HeaderNav.defaultProps = {
  backgroundColor: 'transparent',
  titleStyle: {},
}

export default withRouter(
  connect((state, props) => {
    const {
      deviceinfo: {paddingTop, h_av: appVersion = ''},
    } = state
    const pt = Number(paddingTop) || 0
    return {pt, appVersion}
  })(HeaderNav)
)

import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import PT from 'prop-types'
import {Icon} from 'antd-mobile'
import HeaderNav from '../../components/HeaderNav'
import {closeWindow, isFullScreen as isFullScreenFn} from '../../utils/pwHybrid'
import FullscreenHeaderWrap from '../../components/FullscreenHeaderWrap'
import {isLH} from '../../utils/ua'
import './index.scss'

class NotFound extends React.Component {
  state = {
    isFullScreen: false,
  }

  componentDidMount() {
    // 部分安卓机客户端没有收到调用
    setTimeout(() => {
      isFullScreenFn().then(isFull => this.setState({isFullScreen: isFull}))
    }, 200)
  }

  renderHeader = () => (
    <HeaderNav
      title=""
      // pt={pt}
      titleStyle={{
        fontSize: 17,
        fontWeight: 600,
        color: '#000',
      }}
    />
  )

  renderHeaderOld = () => (
    <FullscreenHeaderWrap className="NotFound__headerWrap">
      <div className="NotFound__header">
        <Icon
          type="left"
          size="lg"
          className="NotFound__headerBack"
          // color="#999"
          color="#000"
          onClick={() => {
            closeWindow()
          }}
        />
      </div>
    </FullscreenHeaderWrap>
  )

  render() {
    return (
      <div>
        {(this.state.isFullScreen ||
          isLH(this.props.userAgent, this.props.hostname)) &&
          this.renderHeaderOld()}
        <div className="NotFound">
          <Helmet>
            <title>页面不存在</title>
            <body className="page-NotFound" />
          </Helmet>
          <div className="NotFound__img" />
          <div className="NotFound__txt">您访问的页面不存在！</div>
        </div>
      </div>
    )
  }
}

NotFound.propTypes = {
  userAgent: PT.string.isRequired,
  hostname: PT.string.isRequired,
}

export default connect((state, props) => {
  const {
    env: {userAgent, hostname},
  } = state
  return {
    userAgent,
    hostname,
  }
})(NotFound)

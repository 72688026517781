/**
 *
 * 全屏页面 Header 容器
 *
 */
import React from 'react'
import cx from 'classnames'
import PT from 'prop-types'
import {connect} from 'react-redux'

const FullscreenHeaderWrap = props => {
  const {paddingTop, className, children, onRef} = props

  return (
    <div
      ref={onRef}
      className={cx('FullscreenHeaderWrap', className)}
      style={{paddingTop}}
    >
      {children}
    </div>
  )
}

FullscreenHeaderWrap.propTypes = {
  paddingTop: PT.number.isRequired,
  className: PT.string,
  children: PT.node.isRequired,
  onRef: PT.func,
}

FullscreenHeaderWrap.defaultProps = {
  className: '',
  onRef: () => {},
}

export default connect(state => {
  const {
    deviceinfo: {paddingTop},
  } = state
  const pt = Number(paddingTop) || 0

  return {paddingTop: pt}
})(FullscreenHeaderWrap)

import React from 'react'
import PT from 'prop-types'
import throttle from 'lodash/throttle'
import './index.scss'

const SCROLL_DISTANCE = 10

class HeaderMask extends React.Component {
  state = {
    showMask: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleBodyScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleBodyScroll)
  }
  toggleMask = isShow => {
    const {showMask} = this.state
    if (isShow !== showMask) {
      this.setState({
        showMask: isShow,
      })
    }
  }
  // eslint-disable-next-line react/sort-comp
  handleBodyScroll = throttle(() => {
    this.toggleMask(window.scrollY > SCROLL_DISTANCE)
  })
  render() {
    const {showMask} = this.state
    const {paddingTop, backgroundColor} = this.props
    return showMask ? (
      <div
        className="HeaderMask"
        style={{
          paddingTop: `${paddingTop}px`,
          backgroundColor,
        }}
      />
    ) : null
  }
}

HeaderMask.propTypes = {
  paddingTop: PT.number.isRequired,
  backgroundColor: PT.string.isRequired,
}

export default HeaderMask
